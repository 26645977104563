import React from "react"
import {parseToNumber, Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"
import { useParams } from "../hooks"

const ComboFlynow = () => {
  const {params} =useParams()

  const CUPOM = params.cupom || 'FLYN25'

  const data = {
    id: "combo_apps",
    title: "FLYNOW PRODUTIVIDADE + FLYNOW FINANÇAS PESSOAIS + FLYNOW FRASES",
    discontPercentage: parseToNumber(CUPOM) || 50,
    URL_CHECKOUT: "https://buy.stripe.com/4gwaGr01P5pX2gU7sQ?locale=pt-BR&prefilled_promo_code="+CUPOM,
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVOS (PLANO PREMIUM VITALÍCIO)",
        data: Products.apps
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow